import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Search from "../components/Search"
import { Container, Paper } from "@material-ui/core"
import AlgoliaSearch from "../components/Search/algoliaSearch"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"

const style = {
  paper: {
    padding: "50px",
    margin: "100px 10px 40px 10px",
  },
}

const SearchPage = () => (
  <Layout>
    <SEO title="CCO Search" />
    <Paper style={style.paper}>
      <GridContainer>
        <GridItem style={{ paddingTop: "50px" }}>
          <AlgoliaSearch />
        </GridItem>
      </GridContainer>
    </Paper>
  </Layout>
)

export default SearchPage
